import { Box, Flex, IconButton, Image, Spacer, Stack } from '@chakra-ui/react'
import { Link, SidebarSection } from '@saas-ui/react'
import { FiChevronsLeft, FiChevronsRight } from 'react-icons/fi'
import { Link as RouterLink } from 'react-router-dom'
import logoSvg from '~/assets/artic_logo.svg'
import logoTruck from '~/assets/artic_logo_truck.svg'
import FactoryPicker from '~/components/Picker/FactoryPicker'
import PartnerPicker from '~/components/Picker/PartnerPicker'

export const SidebarHeader = ({ isOpen, onToggle }: { isOpen: boolean; onToggle: () => void }) => {
  return (
    <SidebarSection>
      <Flex direction={isOpen ? 'row' : 'column'} marginBottom={2} align="center">
        {isOpen && <Box width="40px" />}
        <Link as={RouterLink} to="/">
          <Image src={isOpen ? logoSvg : logoTruck} alt="Artic logo" marginBottom={2} h={'25px'} />
        </Link>
        <Spacer />
        <IconButton
          onClick={onToggle}
          variant="ghost"
          size="sm"
          visibility={{ base: 'hidden', lg: 'visible' }}
          icon={isOpen ? <FiChevronsLeft /> : <FiChevronsRight />}
          aria-label="Toggle Sidebar"
        />
      </Flex>

      {isOpen && (
        <Stack>
          <PartnerPicker />
          <FactoryPicker />
        </Stack>
      )}
    </SidebarSection>
  )
}
